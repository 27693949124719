import QueryInterface from '@/core/application/query/QueryInterface'

export default class TaskRegistryEntityByPropertyQuery implements QueryInterface {
  private property: string;

  constructor (property: string) {
    this.property = property
  }

  getClassName (): string {
    return 'TaskRegistryEntityByPropertyQuery'
  }

  getProperty (): string {
    return this.property
  }
}
